import React, { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home";
import "./App.scss";

function App() {
  return (
      <Fragment>
        <Routes>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/" element={<Navigate to="/home" />}></Route>
        </Routes>
      </Fragment>
  );
}

export default App;
