import React, { useState } from "react";
import { Alert, Col } from "react-bootstrap";
import "./index.scss";

const urlList = [
  {
    value: "https://jx.aidouer.net/?url=",
    label: "球球",
  },
  {
    value: "https://www.yemu.xyz/?url=",
    label: "望舒",
  },
  {
    value: "https://jx.xmflv.com/?url=",
    label: "冰轮",
  },
  {
    value: "https://okjx.cc/?url=",
    label: "落苏",
  },
  {
    value: "https://jx.m3u8.tv/jiexi/?url=",
    label: "柔甲",
  },
  {
    value: "https://jx.blbo.cc:4433/?url=",
    label: "星汉",
  },
  {
    value: "https://jx.nnxv.cn/tv.php?url=",
    label: "扶光",
  },
  {
    value: "https://api.qianqi.net/vip/?url=",
    label: "碧落",
  },
  {
    value: "https://www.ckplayer.vip/jiexi/?url=",
    label: "坤灵",
  },
  {
    value: "https://www.ckmov.vip/api.php?url=",
    label: "寒酥",
  },

  {
    value: "https://www.ckmov.com/?url=",
    label: "纤凝",
  },
  {
    value: "https://vip.bljiex.com/?v=",
    label: "灵泽",
  },
  {
    value: "https://api.jiexi.la/?url=",
    label: "不夜侯",
  },
  {
    value: "https://jiexi.janan.net/jiexi/?url=",
    label: "快乐君",
  },
  {
    value: "https://www.mtosz.com/m3u8.php?url=",
    label: "月华",
  },
  {
    value: "https://www.pangujiexi.cc/jiexi.php?url=",
    label: "倾城",
  },
  {
    value: "https://jx.4kdv.com/?url=",
    label: "日轮",
  },
  {
    value: "https://www.8090g.cn/?url=",
    label: "流年",
  },
  {
    value: "https://www.nxflv.com/?url=",
    label: "苍穹",
  },
  {
    value: "https://jx.000180.top/jx/?url=",
    label: "霹雳",
  },
  {
    value: "https://www.administratorw.com/video.php?url=",
    label: "滚滚",
  },

  {
    value: "https://jx.jsonplayer.com/player/?url=",
    label: "丹落",
  },
  {
    value: "https://api.okjx.cc:3389/jx.php?url=",
    label: "雨别",
  },

  {
    value: "https://jx.iztyy.com/Bei/?url=",
    label: "猪蹄",
  },
  {
    value: "https://jx.yparse.com/index.php?url=",
    label: "碧霞",
  },
  {
    value: "https://www.playm3u8.cn/jiexi.php?url=",
    label: "照夜清",
  },
  {
    value: "https://zhihuweb.com/player.html?url=",
    label: "濯枝",
  },
  {
    value: "https://im1907.top/?jx=",
    label: "疏影（B站）",
  },
  {
    value: "https://jx.bozrc.com:4433/player/?url=",
    label: "纸鸢（B站）",
  },
  {
    value: "https://jx.playerjy.com/?url=",
    label: "扶摇（B站）",
  },
  {
    value: "https://z1.m1907.top/?eps=0&jx=",
    label: "桑梓（B站）",
  },
];

const Home = () => {
  const [defaultLink, setDefaultLink] = useState(
    "https://jx.aidouer.net/?url="
  );
  const [vipUrl, setVipUrl] = useState(
    "https://www.iqiyi.com/v_1bk9icuaae8.html?tip=这是一个示例链接"
  );

  const [fullUrl, setFullUrl] = useState("");
  const [showError, setShowError] = useState(false);

  const logos = [
    {
      url: "https://www.iqiyi.com",
      name: "爱奇艺",
    },
    {
      url: "https://v.youku.com",
      name: "优酷视频",
    },
    {
      url: "https://w.mgtv.com",
      name: "芒果 TV",
    },
    {
      url: "https://www.mgtv.com",
      name: "芒果 TV VIP",
    },
    {
      url: "https://tv.sohu.com",
      name: "搜狐视频",
    },
    {
      url: "https://film.sohu.com",
      name: "搜狐视频 VIP",
    },
    { url: "https://www.le.com", name: "乐视视频" },
    { url: "https://pptv.com", name: "PPTV" },
    {
      url: "https://vip.pptv.com",
      name: "PPTV VIP",
    },
    { url: "https://www.wasu.cn", name: "华数 TV" },
    {
      url: "https://www.bilibili.com",
      name: "哔哩哔哩 B站",
    },
    {
      url: "https://vip.1905.com",
      name: "1905 VIP",
    },
  ];

  return (
    <div id="IUC" className="theme-orange">
      <nav className="navbar navbar-expand-xl py-lg-2 lp-menu">
        <div className="container py-2 rounded">
          <a
            className="navbar-brand pt-2 order-1"
            href="https://zhaoyeqing.cn"
            target="_blank"
            rel="noreferrer"
            title="Logo"
          >
            <img src="https://zhaoyeqing.cn/images/icon/logo.png" width="50px" alt="logo" />
            <span className="fw-bold fs-5 px-2">
              Vip 视频 <i class="bi bi-x-octagon text-primary"></i> 照夜清
            </span>
          </a>
          <div className="d-flex align-items-center order-2 order-md-2 order-xl-3">
            <div className="d-flex align-items-center">
              <span
                data-bs-toggle="modal"
                data-bs-target="#Settingmodal"
                className="setting-icon"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 28C14.036 28 10 23.96 10 19C10 14.036 14.036 10 19 10C23.96 10 28 14.036 28 19C28 23.96 23.96 28 19 28ZM19 16C17.346 16 16 17.346 16 19C16 20.654 17.346 22 19 22C20.654 22 22 20.654 22 19C22 17.346 20.654 16 19 16Z"
                    fill="var(--primary-color)"
                  />
                  <path
                    opacity="0.8"
                    d="M19 24C16.243 24 14 21.757 14 19C14 16.243 16.243 14 19 14C21.757 14 24 16.243 24 19C24 21.757 21.757 24 19 24ZM19 16C17.346 16 16 17.346 16 19C16 20.654 17.346 22 19 22C20.654 22 22 20.654 22 19C22 17.346 20.654 16 19 16ZM32 19C32 17.592 31.768 16.237 31.352 14.966L35.089 13.418L34.323 11.57L30.58 13.121C29.33 10.669 27.329 8.66901 24.878 7.42001L26.429 3.67601L24.581 2.91101L23.033 6.64801C21.762 6.23201 20.409 6.00001 19 6.00001C17.591 6.00001 16.244 6.24801 14.974 6.66801L13.418 2.91201L11.57 3.67701L13.886 9.26901C15.416 8.46201 17.154 8.00001 19 8.00001C25.065 8.00001 30 12.935 30 19C30 25.065 25.065 30 19 30C12.935 30 8.00001 25.065 8.00001 19C8.00001 15.969 9.23201 13.221 11.222 11.229L9.80801 9.81601C8.84601 10.779 8.04401 11.898 7.42001 13.122L3.67601 11.571L2.91101 13.418L6.64901 14.966C6.23201 16.238 6.00001 17.592 6.00001 19C6.00001 20.409 6.23201 21.763 6.64801 23.034L2.91101 24.582L3.67701 26.43L7.42101 24.879C8.67101 27.33 10.671 29.33 13.122 30.579L11.571 34.323L13.419 35.089L14.967 31.352C16.237 31.768 17.591 32 19 32C20.409 32 21.762 31.768 23.033 31.352L24.582 35.089L26.43 34.323L24.878 30.58C27.329 29.33 29.329 27.33 30.579 24.879L34.323 26.43L35.088 24.582L31.352 23.034C31.768 21.763 32 20.409 32 19Z"
                    fill="var(--text-color)"
                  />
                </svg>
              </span>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-expanded="false"
              >
                <span className="navbar-toggler-icon">
                  <i className="fa fa-bars"></i>
                </span>
              </button>
            </div>
          </div>
          <div
            className="collapse navbar-collapse order-3 order-md-3 order-xl-2"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link me-2 lead"
                  href="https://zhaoyeqing.cn"
                  target="_blank"
                  rel="noreferrer"
                >
                  总舵
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link me-2 lead"
                  href="https://ai-site.zhaoyeqing.cn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ai 网站收录
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link me-2 lead"
                  href="https://chatgpt-site.zhaoyeqing.cn"
                  target="_blank"
                  rel="noreferrer"
                >
                  ChatGPT 全球站点
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link me-2 lead"
                  href="https://hot.zhaoyeqing.cn"
                  target="_blank"
                  rel="noreferrer"
                >
                  今日热榜
                </a>
              </li>
              <li className="nav-item cursor-pointer">
                <a
                  href="#"
                  className="nav-link me-2 lead"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Ai 动物语言识别
                </a>
              </li>
              <li className="nav-item cursor-pointer">
                <a
                  href="https://zero.zhaoyeqing.cn/"
                  className="nav-link me-2 lead"
                  target="_blank"
                  rel="noreferrer"
                >
                  未开发功能
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="section pt-0">
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="section-title d-flex justify-content-between align-items-end flex-wrap border-before">
                <h1>免费观看 Vip 视频</h1>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row g-3">
                    <div className="col-lg-2">
                      <select
                        className="form-select form-select-lg"
                        aria-label="Default select example"
                        value={defaultLink}
                        onChange={(e) => setDefaultLink(e.target.value)}
                      >
                        {urlList.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="search"
                        onChange={(e) => setVipUrl(e.target.value)}
                        value={vipUrl}
                        className="form-control form-control-lg"
                        placeholder="输入您想看的视频链接"
                      />
                      <Alert
                        variant="danger"
                        show={showError}
                        onClose={() => setShowError(false)}
                        dismissible
                        className="mt-4"
                      >
                        帅哥 or 美女 能不能输个链接
                        🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗🔗 ？
                      </Alert>
                    </div>
                    <div className="col-lg-2">
                      <button
                        className="btn btn-primary w-100 btn-lg"
                        onClick={() => {
                          if (vipUrl) {
                            setFullUrl(defaultLink + vipUrl);
                          } else {
                            setShowError(true);
                          }
                        }}
                      >
                        立即免费观看
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 justify-content-md-center">
            <Col xs={12} md={8}>
              <div className="ratio ratio-16x9 bg-dark">
                <iframe
                  src={fullUrl}
                  title="REO Speedwagon - Can&#39;t Fight This Feeling (Official HD Video)"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
          </div>
          <div className="row mb-3 justify-content-md-center">
            <Col xs={12} md={8}>
              <ul class="list-group list-group-numbered">
                <li class="list-group-item">
                  在各大视频平台，比如爱奇艺、腾讯视频、哔哩哔哩。。。找到需要
                  Vip 才能观看的视频
                </li>
                <li class="list-group-item">
                  复制链接到输入框里面，点击立即免费观看
                </li>
                <li class="list-group-item">
                  如果没有成功解析视频，最左侧下拉选择多试试几个
                </li>
                <li class="list-group-item">
                  视频解析接口收集于网络平台，仅供学习交流
                </li>
                <li class="list-group-item">
                  视频中的广告信息请勿相信，造成损失后果自负哦
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </div>

      <div className="section pt-0">
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="section-title d-flex justify-content-between align-items-end flex-wrap border-before">
                <h2>支持的平台</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="list-unstyled row g-3 g-md-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list-number mb-0">
                {logos.map((item, index) => {
                  return (
                    <li className="col" key={index}>
                      <div className="d-flex align-items-center border border-dashed p-3">
                        <div className="position-relative">
                          <i
                            className="bi bi-person-video2 mr-6"
                            style={{ fontSize: "30px", marginRight: "6px" }}
                          ></i>
                        </div>
                        <div>
                          <a
                            href={item.url}
                            title=""
                            className="lead"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section footer pb-0 mt-5 border-top">
        <div className="container">
          <div className="row g-3 justify-content-between mb-5">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="d-flex align-items-center mb-3">
                <a className="navbar-brand p-0" href="https://zhaoyeqing.cn" title="Logo">
                  <img src="https://zhaoyeqing.cn/images/icon/logo.png" width="50px" alt="logo" />
                  <span className="fw-bold fs-5 px-2">照夜清</span>
                </a>
              </div>
              <span className="lead">
                宋·彭乘《墨客挥犀》卷四：照夜清，萤也。
              </span>
              <div className="social-share d-flex justify-content-start align-items-center mt-3">
                <a
                  className="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary"
                  href="https://www.instagram.com/"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  className="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary"
                  href="https://www.twitter.com/"
                >
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  className="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary"
                  href="https://www.facebook.com/"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  className="d-flex justify-content-center align-items-center border me-2 avatar rounded-circle border-primary"
                  href="https://www.linkedin.com/"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom border-top pt-4">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  Designed and Developed by @
                  <a
                    href="https://zhaoyeqing.cn"
                    rel="noreferrer"
                    target="_blank"
                    className="text-primary"
                  >
                    照夜清
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade right"
        id="Settingmodal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Settings</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body custom_setting">
              <div className="setting-theme pb-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  Skin Color
                </h6>
                <ul className="list-unstyled row g-1 choose-skin mb-2 mt-2">
                  <li className="col-6 col-sm-4" data-theme="indigo">
                    <div className="btn-shap">
                      <div className="color-dot indigo"></div>
                      Indigo
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="tradewind">
                    <div className="btn-shap">
                      <div className="color-dot tradewind"></div>
                      Tradewind
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="monalisa">
                    <div className="btn-shap">
                      <div className="color-dot monalisa"></div>
                      Monalisa
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="blue">
                    <div className="btn-shap">
                      <div className="color-dot blue"></div>
                      Blue
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="cyan">
                    <div className="btn-shap">
                      <div className="color-dot cyan"></div>
                      Cyan
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="green">
                    <div className="btn-shap">
                      <div className="color-dot green"></div>
                      Green
                    </div>
                  </li>
                  <li className="col-6 col-sm-4 active" data-theme="orange">
                    <div className="btn-shap">
                      <div className="color-dot orange"></div>
                      Orange
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="blush">
                    <div className="btn-shap">
                      <div className="color-dot blush"></div>
                      Blush
                    </div>
                  </li>
                  <li className="col-6 col-sm-4" data-theme="red">
                    <div className="btn-shap">
                      <div className="color-dot red"></div>
                      Red
                    </div>
                  </li>
                </ul>
              </div>
              <div className="dynamic-block py-3">
                <ul className="list-unstyled choose-skin mb-2 mt-1">
                  <li data-theme="dynamic">
                    <div className="btn btn-outline-primary w-100 border-dashed">
                      Click to Dyanmic Setting
                    </div>
                  </li>
                </ul>
                <div className="dt-setting">
                  <ul className="list-group list-unstyled mt-1">
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label>Primary Color</label>
                      <button
                        id="primaryColorPicker"
                        className="btn bg-primary avatar xs border-0 rounded-0"
                      ></button>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label>Secondary Color</label>
                      <button
                        id="secondaryColorPicker"
                        className="btn bg-secondary avatar xs border-0 rounded-0"
                      ></button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="setting-font py-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  Font Settings
                </h6>
                <ul className="list-group font_setting mt-1 border border-primary border-dashed p-2">
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-poppins"
                        value="font-poppins"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-poppins"
                      >
                        Poppins Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-opensans"
                        value="font-opensans"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-opensans"
                      >
                        Open Sans Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-montserrat"
                        value="font-montserrat"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-montserrat"
                      >
                        Montserrat Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-Plex"
                        value="font-Plex"
                      />
                      <label className="form-check-label" htmlFor="font-Plex">
                        Plex Google Font
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="setting-mode py-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  Contrast Layout
                </h6>
                <ul className="list-group list-unstyled mb-0 mt-1 border border-primary border-dashed p-2">
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-switch mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-switch"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-switch"
                      >
                        Enable Dark Mode!
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-high-contrast mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-high-contrast"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-high-contrast"
                      >
                        Enable High Contrast
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-rtl mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-rtl"
                      />
                      <label className="form-check-label" htmlFor="theme-rtl">
                        Enable RTL Mode!
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-white border lift"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary lift">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- 对话机 Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ai 动物语言识别
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h3 className="text-center">二十一世纪最伟大的发明</h3>
              <p>
                {" "}
                AI
                动物语言识别是一种利用人工智能技术来识别和理解动物语言的技术。它可以通过分析动物的声音、姿势、表情等多种信息来推测出其表达的情感和意图，并通过语音转译成人类的语言是我们可以听懂它们的表达，同样我们也可以将我们说的话转译成动物所能听懂的语音，达到相互交流的目的。目前，AI动物语言识别技术正在不断发展，主要包括：
              </p>
              <p>
                语音识别技术：利用语音信号处理算法，将动物的声音转化为数字信号，并进行声学特征提取和模式识别。
                <br />
                计算机视觉技术：通过分析动物的姿势、表情等视觉信息，推测其情感和意图。
                <br />
                自然语言处理技术：利用自然语言处理算法，对动物语言进行分析、理解和翻译。
                <br />
                情感识别技术：通过分析动物的声音和行为表现，推测其情感状态和情感变化。
                <br />
                语言转译技术：通过转移动物的语言和人类的语言实现无障碍沟通。
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                我会关注的！
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
